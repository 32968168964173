$env: production;
@import '@/styles/_component-base.styles.scss';

.section {
    padding: 0;
}

.container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    overflow: visible;
    min-height: 400px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    
    @include responsive-dpi-imgix('/quiz/mattress-quiz-lifestyle', 'mattress-quiz-lifestyle', 375, 768, 1439);

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(color(text, base), 0.2);
    }

    @include breakpoint(sm) {
        min-height: 150px;
        background-position: center 85%;
    }

    @include breakpoint(md) {
        min-height: 150px;
        background-position: center 35%;
    }

    @include breakpoint(lg) {
        min-height: 200px;
    }
}

.content {
    padding: spacer(3xl) spacer(3xl) 0 spacer(3xl);
    position: relative;
    width: 100%;

    @include breakpoint(sm) {
      padding-bottom: spacer(xl);
    }

    @include breakpoint(md) {
      padding-bottom: spacer(md);
    }

    @include breakpoint(lg) {
      padding-bottom: spacer(lg);
    }
}

.title {
    margin: 0 auto spacer(xs);

    @include breakpoint(md) {
      margin-bottom: spacer(sm);
      font-size: set-fontSize(28);
    }

    @include breakpoint(lg) {
      font-size: set-fontSize(36);
    }
}

.button {
    margin: 0 auto;
    width: 230px;

    &--compareCTA {
      margin-top: spacer(base);

      @include breakpoint(md) {
        margin-top: 0;
        margin-left: spacer(lg);
      }
    }
  }