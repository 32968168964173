$env: production;
@import '@saatva-bits/pattern-library.styles.config/_index.scss';


.container {
    font-weight: 400;
    @include set-font(16, 1);
}

.finalPrice {
    display: inline-block;
    margin-right: spacer(xs);
}

.fullPrice {
    display: inline-block;
    color: color(text, hover);
    text-decoration: line-through;
}
