$env: production;
@import '@saatva-bits/pattern-library.styles.config/_index.scss';

.desktopContainer {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    width: 100%;
}

.imageContainerDesktop {
    padding-bottom: spacer(base);
}

.featuredImage {
    width: 100%;
    height: auto;
    aspect-ratio: 3/2;
}

.mobileContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.imageContainerMobile {
    padding-bottom: spacer(base);
}

.sliderContainer {
    display: flex;
    overflow-x: auto;
    width: 100%;
    padding-bottom: spacer(base);
}

.sliderItem {
    flex: 1;
    min-height: 70px;
    min-width: 70px;
    padding: spacer(2xs);

    @include breakpoint(md) {
        max-height: 124px;
        max-width: 124px;
    }

    &:hover {
        cursor: pointer;
    }

    &-isActive,
    &:focus {
        border: 2px #858585 solid;
    }
}

.sliderImageContainer {
    height: 100%;
    width: 100%;
}

.sliderImage {
    height: 100%;
    width: 100%;
    background-position: center;
}