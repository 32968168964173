$env: production;
@import '@/styles/_component-base.styles.scss';

.container{
    display: flex;
    gap: spacer(base)
}

.addButton {
    width: 100%;
    margin-bottom: spacer(sm);
    min-width: auto;

    &-soldOut {
        margin-top: 0px;
    }
}

.loader{
    background-color: rgba(121, 115, 107, 0.8);
    
    &Dots{
        background-color: color(contrast, white);
    }
}