$env: production;
@import '@/styles/_component-base.styles.scss';

.soldOutMessage {
    padding: spacer(sm) spacer(base);
    margin: spacer(2xl) auto auto auto;
    background-color: colorHexOpacity(color(accent, base), 20);
    @include set-font(14px, 16px);
    text-align: center;

    &-mobile {
        position: fixed;
        bottom: 20px;
        width: 100%;
    }
}

.soldOutMessageLink {
    white-space: nowrap;
}