$env: production;
@import "@/styles/component-base.styles.scss";


.buttonsContainer {
    min-width: 120px;
    height: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: color(contrast, 2);
    border: 1px solid color(contrast, 2);
}

.quantity {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid color(contrast, 3);
    background-color: color(contrast, white);
    font-size: set-fontSize(14);
    font-weight: normal;
    color: color(text, base)
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: color(contrast, 2);
    transition: 0.3s;

    &:hover {
        background-color: color(contrast, 4);
    }

    &:active {
        background-color: color(darkContrast, 2);
        border-color: color(darkContrast, 1);
    }

    &:disabled{
        cursor: not-allowed;
        
        &:hover, &:active {
            background-color: unset;
        }

        svg{
            stroke: color(contrast, 3);
        }
    }
}