$env: production;
@import "@saatva-bits/pattern-library.styles.config/_index.scss";

.container {
    padding: 0;
}

.title {
    margin-bottom: 0;
    padding-bottom: 0;
    font-weight: 400;
    font-size: set-fontSize(24);

    @include breakpoint(md) {
        font-size: set-fontSize(28);
    }
}

.subtitle {
    max-width: 100%;
    font-weight: 400;
    margin-top: spacer(2xs);
    font-size: set-fontSize(14);
    font-family: $fontFamily-sans-serif;
}

.priceRange {
    line-height: 1.2;
    text-align: left;
    padding-top: spacer(xs);
    padding-bottom: spacer(xs);
    font-size: set-fontSize(20);
    font-family: $fontFamily-sans-serif;
}

.price,
.strikeThrough {
    font-weight: 400;
    font-size: set-fontSize(20);
}

.price {
    margin-top: 0;
    padding-top: 0;
    line-height: 25px;
    margin: spacer(xs) 0;
}

.strikeThrough {
    margin-left: spacer(xs);
    color: color(text, hover);
    text-decoration: line-through;
}

.discountLabelContainer {
    display: flex;
    column-gap: spacer(xs);
    flex-wrap: wrap;
}