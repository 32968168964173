$env: production;
@import '@saatva-bits/pattern-library.styles.config/_index.scss';

// Raise specificity for higher envs issues on sale page
div.modal {
    height: 85dvh;
    border-radius: 5px;
    padding-top: spacer(2dot5xl);
    overflow: unset;

    @include breakpoint(md) {
        min-width: 600px;
        padding-top: spacer(3xl);
    }

    @include breakpoint(lg) {
        padding: spacer(lg) spacer(md);
        min-width: 976px;
        overflow: hidden;
    }
}

.modal span[role="tooltip"] aside {
    @include only-breakpoint(md) {
        right: unset;
    }
}

.closeButton {
    @include breakpoint(md) {
        position: absolute;
        cursor: pointer;
        z-index: 4001;
        top: 1.5rem;
        right: calc(4rem / 2);
        transform: translate(50%, -50%);
        width: 1.25rem;
        height: 1.25rem;
    }
}

// Raise specificity for higher envs issues on sale page
div.modalBaseContainer {
    padding: 0 spacer(md) spacer(lg);
    overflow: auto;

    @include breakpoint(lg) {
        padding: 0;
        height: 100%;
    }
}

// Raise specificity for higher envs issues on sale page
div.modalContent {
    justify-content: flex-start;

    @include breakpoint(md){
        padding: 0 spacer(md)
    }

    @include breakpoint(lg) {
        padding: 0;
    }
}

.mainClasses {
    margin: 0;
    @include breakpoint(md) {
        margin: 0;
    }
    
    @include breakpoint(lg) {
        height: 100%;
        margin-right: spacer(base);
    }
}

// Raise specificity for higher envs issues on sale page
div.contentClasses {
    @include between-breakpoint(xs, sm){
        padding: spacer(base) 0;
    }

    @include breakpoint(md){
        padding-bottom: spacer(3xl)
    }

    @include breakpoint(lg) {
        height: 100%;
        overflow-y: auto;
        padding-right: spacer(base);
    }
}


.accordion {
    border-radius: 2px;
    border: solid color(action, darkHover) 2px;

    &Title {
        font-weight: 400;
        color: color(text, base);
        font-size: set-fontSize(14);
    }

    &ContentContainer {
        padding-bottom: spacer(xs);
    }

    &Content {
        color: color(text, base);

        ul, ol {
            margin-left: spacer(lg);
            margin-bottom: spacer(xs);
        }

        ul {
            list-style-type: disc;
        }

        ol {
            list-style-type: decimal;
        }

        li {
            font-weight: 400;
            line-height: 24px;
            margin-bottom: spacer(2xs);
            font-size: set-fontSize(14);
        }

        p {
            font-weight: 400;
            margin-bottom: spacer(xs);
            font-size: set-fontSize(14);
        }

        strong {
            font-weight: 600;
        }
    }

    &Icon {
        stroke: color(cta, base);
    }

    &NoTransition {
        transition: none;
    }
}
