$env: production;
@import '@saatva-bits/pattern-library.styles.config/_index.scss';

.imageContainer {
    width: 100%;
}

.image {
    width: 100%;
    height: auto;
}

.imagePicture {
    width: 100%;
}

.favoriteContainer {
    position: absolute;
    top: spacer(xs);
    right: 0;

    @include breakpoint(lg) {
        right: spacer(xs);
    }
}